<template>
    <div class="eat">
        <div class="all">
            <div class="sin">
                <p class="txt">应就餐人数</p>
                <p>
                    <span class="num yellow">{{info.personTotal}}</span>
                    <span class="unit">人</span>
                </p>
            </div>
            <div class="sin">
                <p class="txt">应就餐人次</p>
                <p>
                    <span class="num blue">{{info.personTotalOrder}}</span>
                    <span class="unit">人</span>
                </p>
            </div>
        </div>
        <div class="today">
            <div class="sin">
                <img src="../../../assets/images/icon_number@2x.png" alt="">
                <p class="txt">今日就餐人数(人)</p>
            </div>
            <div class="sin">
                <img src="../../../assets/images/icon_number@2x.png" alt="">
                <p class="txt">今日就餐人次(次)</p>
            </div>
        </div>
        <div class="count">
            <div class="lef">
                <div v-for="(item, index) in info.personNum" :key="index" class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num org">{{item}}</p>
                </div>
                <!-- <div class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num org">1</p>
                </div>
                <div class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num org">1</p>
                </div>
                <div class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num org">1</p>
                </div> -->
            </div>
            <div class="rig">
                <div v-for="(item, index) in info.personOrder" :key="index" class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num blue">{{item}}</p>
                </div>
                <!-- <div class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num blue">1</p>
                </div>
                <div class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num blue">1</p>
                </div>
                <div class="sin">
                    <img src="../../../assets/images/bg_number@2x(2).png" alt="">
                    <p class="num blue">1</p>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import {getNumberOfDiners} from '../../../api/index.js'

export default {
    name: '',
    props: {
        syncId: String
    },
    data() {
        return {
            info: {
                personOrder: "0000",
                personNum: "0000"
            }
        }
    },
    methods: {
    },
    mounted() {
        if (!this.syncId) return
        getNumberOfDiners({syncId: this.syncId}).then(res => {
            this.info = res.data
            this.info.personOrder = JSON.stringify(this.info.personOrder)
            this.info.personNum = JSON.stringify(this.info.personNum)

            if (this.info.personOrder.length === 1) {
                this.info.personOrder = "000" + this.info.personOrder
            } else if (this.info.personOrder.length === 2) {
                this.info.personOrder = "00" + this.info.personOrder
            } else if (this.info.personOrder.length === 3) {
                this.info.personOrder = "0" + this.info.personOrder
            }
            if (this.info.personNum.length === 1) {
                this.info.personNum = "000" + this.info.personNum
            } else if (this.info.personNum.length === 2) {
                this.info.personNum = "00" + this.info.personNum
            } else if (this.info.personNum.length === 3) {
                this.info.personNum = "0" + this.info.personNum
            }
        })
    },
}
</script>

<style lang="less">
.eat{
    padding-top: 2%;
    .all{
        display: flex;
        align-items: center;
        justify-content: center;
        .sin{
            width: 42%;
            height: 3em;
            display: flex;
            align-items: center;
            // background: #FFFFFF;
            background: rgba(79,128,255,0.2);
            box-shadow: inset 0px 0px 40px 1px #446DFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #4F80FF;
            padding: 0 1.5em;
            justify-content: space-between;
            .yellow{
                color: #FFCD3E;
            }
            .blue{
                color: #3EF3FF;
            }
            &:nth-child(2) {
                margin-left: 5%;
            }
            .txt{
                color: #fff;
                font-size: 0.8em;
            }
            .num{
                font-size: 1.3em;
            }
            .unit{
                color: #fff;
                font-size: 0.8em;
                padding-left: 2px;
            }
        }
    }
    .today{
        display: flex;
        margin-top: 20px;
        .sin{
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            justify-content: center;
            img{
                width: 6em;
                height: auto;
            }
            .txt{
                padding-top: 14px;
                font-size: 1em;
                color: #fff;
            }
        }        
    }
    .count{
        display: flex;
        align-items: center;
        .lef,.rig{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .sin{
            width: 3em;
            position: relative;
            margin-left: 0em;
            img{
                width: 100%;
                height: auto;
            }
            .num{
                font-size: 2em;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -0.3em;
            }
            .blue{
                color: #3EF3FF;
            }
            .org{
                color: #FFCD3E;
            }
        }
    }
    .count{
        margin-top: 1.6em;
    }
}
</style>